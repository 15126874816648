"use strict";

require('bootstrap');
require('jquery-form/jquery.form');
require('../scss/portal.scss');

var btn = $('#button');

$(window).scroll(function () {

    if ($(window).scrollTop() > 300) {
        btn
            .addClass('button show')
            .removeClass('d-none');
    } else {
        btn
            .removeClass('button show')
            .addClass('d-none');
    }

});
function scrollToTarget(target) {
    console.log(target.offset().top);
    if( target.length ) {
        $('html, body').stop().animate({
            scrollTop: target.offset().top > 110 ? target.offset().top - 110 : target.offset().top
        }, 500);
    }
}
$('a[href^="#"], a[href^="/#"]').on('click', function(event) {

    var target = $(this.getAttribute('href').substring(0,1) == '/' ? this.getAttribute('href').substring(1) : this.getAttribute('href'));

    if( target.length ) {
        event.preventDefault();
        scrollToTarget(target);
    }

});

/* post module toggle animation */
$('.card').hover(function () {

    $(this).find('.card-text').stop().animate({
        height: "toggle",
        opacity: "toggle"
    }, 300);

});


$('form#contactform').on('submit', function(e) {
    e.preventDefault();
    var status = $('#status');
    $(this).ajaxSubmit({
        resetForm: true,
        dataType: 'json',
        beforeSend: function() {
            $('form#contactform').children('input, textarea, button').attr("disabled", "disabled");
            status.html($('form').attr('data-upload-message'));
            status.removeClass('error');
            $('.form-group').each(function() {
                $(this).removeClass('has-error');
            });
            $('form ul.error-list').remove();
        },
        error: function (response) {
            status.addClass('error');
            response = response.responseJSON;

            if (response.hasOwnProperty('errors')) {
                $.each(response.errors, function (key, data) {
                    console.log(key);
                    $('#contactform_'+key).addClass('has-error');
                    var list = $(document.createElement('ul')).addClass("error-list");
                    $.each(data, function (index, data) {
                        list
                            .append($(document.createElement('li')).html(data))
                            .insertAfter($('#contactform_'+key));
                    })
                })
            }
        },
        success: function(response) {
        },
        complete: function(response) {
            response = response.responseJSON;

            if (response.hasOwnProperty('message')) {
                status.html(response.message);
            } else {
                console.log('no hasOwnProperty complete');
            }

            $('form#contactform').children('input, textarea, button').removeAttr("disabled");
            scrollToTarget($('#kapcsolat'));
        }
    });
});



var togglerIcon = $('.brw-navbar-toggler-icon');

// close hamburger menu after click a
togglerIcon.click(function() {
    if ($('.navbar-collapse.collapse.show')) {
        $('.navbar-nav li a').on("click", function () {
            $('.navbar-toggler').click();
        });
    }
});
// close toggler icon after click navbar-toggler
togglerIcon.click(function() {
    var toggleBtn = $('span.close-toggler', this);
    if (toggleBtn.hasClass('btn-close-icon')){
        toggleBtn.removeClass('btn-close-icon');
    } else {
        toggleBtn.addClass('btn-close-icon');
    }

});
